import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Link from "gatsby-link";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";

const BlogPostTemplate = ({ data }) => {
  const post = data?.contentfulBlogPost;
  const siteTitle = data?.site?.siteMetadata?.title;

  return (
    <Layout>
      <Helmet title={`${post.title} | ${siteTitle}`} />
      <div className="text-lg max-w-prose mx-auto">
        <div className="prose prose-lg py-4">
          <Link to="/"> Back To Homepage</Link>
        </div>
        {!post.video && (
          <Img
            className="mx-auto object-fill h-full w-full shadow-lg sm:rounded-lg"
            alt={post.title}
            fluid={post.heroImage.fluid}
          />
        )}
      </div>
      <div className="relative py-8 sm:py-12 overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto mb-6">
            <a
              href={post.url}
              className="block text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase"
            >
              {post.publication}
            </a>
            <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              {post.title}
            </h1>
            <ReactMarkdown className="text-xl text-gray-500 leading-8">
              {post.description.childMarkdownRemark.rawMarkdownBody}
            </ReactMarkdown>
          </div>
          {post.video && (
            <video controls className="w-full">
              <source src={post.video.file.url} type="video/mp4" />
            </video>
          )}
          <div className="prose prose-lg text-gray-500 mx-auto bg-transparent">
            {post.body && (
              <ReactMarkdown>
                {post.body.childMarkdownRemark.rawMarkdownBody}
              </ReactMarkdown>
            )}
            {post.publication && (
              <p>
                Read the full article on{" "}
                <a href={post.url} title={`${post.publication}: ${post.title}`}>
                  {post.publication}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publication
      url
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      video {
        file {
          url
        }
      }
    }
  }
`;
